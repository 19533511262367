* {
  box-sizing: border-box;
}

html, body {
  font-size: 13px;
  font-family: Helvetica, Arial, Geneva, sans-serif;
  color: #666;
  margin: 0;
  padding: 0;
  height: 100%;
}

a, a:visited {
  color: #445ebb;
}
