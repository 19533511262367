@import './variables.scss';

header {
  height: 75px;
  min-width: $one-home-card-width-padded;
  background-color: #333366;
  padding: 0 $side-padding;
  color: white;
}

.header__inner {
  max-width: $three-home-cards-width;
  margin: 0 auto;
  height: 100%;
  display: grid;
  grid-template-columns: [logo] auto [middle] 1fr [right] auto;
  align-items: center;
}

.header__logo {
  grid-column: logo;
  margin-top: -10px;
  margin-right: 38px;
}

.header__middle {
  grid-column: middle;

  @media (max-width: 799px) {
    display: none;
  }
}

.header__right {
  grid-column: right;
  margin-left: 120px;

  @media (max-width: 799px) {
    margin-left: 0;
  }

  a, a:visited {
    color: white;
  }
}
